<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3>Editar Categoría</h3>
      </CCardHeader>
      <CCardBody>
        <CTabs variant="pills">
          <CTab title="Datos básicos">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="contact-form" method="post" v-on:submit.prevent="handleSubmit(actualizar)">
                <CCard>
                  <CCardBody>
                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <label>Categoria:</label>
                          <validation-provider
                              name="Categoria"
                              :rules="{ required: true }"
                              :customMessages="{required: 'Es un campo requerido'}"
                              v-slot="validationContext"
                          >
                            <input type="text" v-model="registro.categoria" class="form-control"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <label>
                            Descripción:
                          </label>
                            <input type="text" v-model="registro.descripcion" class="form-control"/>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <label>
                            Idioma:
                          </label>
                          <validation-provider
                              name="Idioma"
                              :rules="{ required: true }"
                              :customMessages="{
                          required: 'Es un campo requerido'
                        }"
                              v-slot="validationContext"
                          >
                            <selectIdioma @ActualizandoId="setIdioma" :idiomaId="idiomaId"/>
                            <input type="hidden" v-model="registro.eco_idioma_id"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <label>
                            Estado:
                          </label>
                          <validation-provider
                              name="Estado"
                              :rules="{ required: true }"
                              :customMessages="{ required: 'Es un campo requerido' }"
                              v-slot="validationContext"
                          >
                            <selectEstado @ActualizandoId="setEstado" :estadoId="estadoId"/>
                            <input type="hidden" v-model="registro.eco_estado_publicacion_id"/>

                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12">
                        <div class="form-group row">
                          <label class="col-md-4 form-control-label">Imagen:</label>
                          <div class="col-md-12" v-if="!imagen_subida">
                            <img style="max-height: 100px;" :src="registro.imagen" alt="" />
                            <input
                                name="inputFile"
                                @change="handleImage"
                                class="form-control"
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                            />
                          </div>
                          <div class="col-md-9" v-if="imagen_subida">
                            <img
                                style="max-height: 100px; width: 100px"
                                :src="urlImage + 'eco/small_' + registro.imagen"
                                alt=""
                            />&nbsp;
                            <button
                                type="button"
                                class="btn btn-sm btn-danger"
                                @click="borrarImagen(registro.id, registro.imagen)"
                            >
                              <i class="cil-trash"></i>
                              Borrar
                            </button>
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <button type="button" class="btn btn-primary mr-2" @click="actualizar()">
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                            Actualizar
                          </button>
                          <router-link to="/ecoCategorias/List">
                            <CButton color="secondary">Cancelar</CButton>
                          </router-link>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </form>
            </ValidationObserver>
          </CTab>

          <CTab title="Traducción">
            <ecoCategoriasTraduccion :eco_categoria_id="id"></ecoCategoriasTraduccion>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectIdioma from '@/components/SelectIdioma.vue'
import selectEstado from '@/components/SelectEstado.vue'
import ecoCategoriasTraduccion from './components/ecoCategoriasTraduccion.vue'

export default {
  components: {
    selectIdioma,
    selectEstado,
    ecoCategoriasTraduccion,
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: 0,
      registro: {},
      idiomaId: '',
      estadoId: '',
    }
  },

  methods: {
    async getRegistro() {
      this.id = this.$route.params.id
      if (this.id !== '0') {
        let url = 'eco-categorias/' + this.id
        let response = await httpService.get(url)
        let respuesta = response.data
        this.registro = respuesta.registros[0]
        this.idiomaId = this.registro.eco_idioma_id.toString()
        this.estadoId = this.registro.eco_estado_publicacion_id.toString()
        this.registro.imagen_subida = this.registro.imagen !== ''
      }
    },
    async actualizar() {
      try {
        let url = 'eco-categorias/' + this.id
        let response = await httpService.put(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        this.$router.back()
      } catch (error) {
        console.log(error)
      }
    },
    setIdioma(value) {
      this.registro.eco_idioma_id = value
    },
    setEstado(value) {
      this.registro.eco_estado_publicacion_id = value
    },

    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      if (selectedImage.type === 'image/jpeg' || selectedImage.type === 'image/jpg' || selectedImage.type === 'image/png') {
        this.createBase64Image(selectedImage);
      } else {
        this.registro.imagen = 'invalid';
        Vue.$toast.success('Tipo de imagen no válida. Por favor, seleccione otro archivo.', {
          type: 'error',
          position: 'top-right'
        });
      }
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.registro.imagen = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    async borrarImagen(id = '', imagen = '') {
      let url = "eco-categorias/eliminar-imagen/" + id;
      await axios.delete(url);
    }

  },

  mounted() {
    this.id = this.$route.params.id
    this.getRegistro()
  }
}
</script>